<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router/auto";
import useCarriers from "../../../hooks/useCarriers.js";
import { setPageTitle } from "@/utils/pageTitle";
import CarrierLayout from "@/layouts/CarrierLayout.vue";
import PendingOrders from "@/components/carriers/PendingOrders.vue";

const route = useRoute();

const { fetchCarrier, carrier } = useCarriers();

onMounted(async () => {
  await fetchCarrier(route.params.id);
  setPageTitle(`Carrier // ${carrier.value.id}`);
});

</script>

<template>
  <CarrierLayout v-if="carrier" :carrier="carrier" title="Pending Orders">
    <v-container class="bg-surface-light" variant="flat">
      <div class="px-4 py-2">Pending Orders</div>
      <PendingOrders/>
    </v-container>
  </CarrierLayout>
</template>