<script setup>
import { ref } from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
  saveGridState,
  loadGridState,
  connectToServerApi,
} from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";
import { useRoute } from "vue-router";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const route = useRoute();
const themeStore = useThemeStore();
const GRID_SAVE_KEY = "pending-orders";
let gridApi;
let context = ref(null);
const PENDING_ORDERS_URL = `/carriers/${route.params.id}/pending_orders`;

const advancedFilterKeys = [
  "client.name",
  "po_number",
  "total_pieces",
  "total_pallets",
  "ship_name"
];

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: PENDING_ORDERS_URL,
    advancedFilterKeys: advancedFilterKeys,
    loader: false,
  };
  loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = getDefaultColDefs();
const columnDefs = [
  { field: "order_status.name", minWidth: 150, headerName: "Order Status", cellDataType: "text" }, 
  { field: "client.name", minWidth: 200, headerName: "Shipper", cellDataType: "text" },
  { field: "po_number", minWidth: 200, headerName: "PO Number", cellDataType: "text" },
  { field: "total_pieces", minWidth: 120, headerName: "Total Pcs", cellDataType: "text" },
  { field: "total_pallets", minWidth: 100, headerName: "Pallets", cellDataType: "text" },
  {
    field: "total_weight", // need to discuss which to map here
    minWidth: 100,
    headerName: "Wt. (LB)",
    cellDataType: "text",
  },
  { field: "pickup_date", minWidth: 150, headerName: "Avail. Date", cellDataType: "text" }, // need to discuss which to map here
  {
    field: "ship_name",
    minWidth: 300,
    headerName: "Destination",
    cellDataType: "text",
    valueGetter: (p) => p.data.ship_name + " - " + p.data.ship_city + ", " + p.data.ship_state,
  }
];

const gridOptions = getDefaultGridOptions(columnDefs);

</script>

<template>
  <AgGridVue
    style="width: 100%; height: 100vh"
    :class="themeStore.agTheme"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :gridOptions="gridOptions"
    @state-updated="onStateUpdated"
    @grid-ready="onGridReady"
    :context="context"
  ></AgGridVue>
</template>